.App {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
  background-color: #191414;
  color: #FFFFFF;
}

#d3-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.viz {
  width: 100%;
  height: 100%;
  background-color: #191414;
  position: relative;
}

#svg_class {
  width: 100%;
  height: 100%;
  display: block;
}

.tooltip {
  position: fixed;
  z-index: 1000;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s;
  font-family: sans-serif;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.tooltip.visible {
  opacity: 1;
}